import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _569f5a48 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _49bee534 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _9e37e1f0 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _44159672 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2f20d248 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _3beca707 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _78dd10f8 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _458b670f = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _66c90a18 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _50cf74b1 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _18099fba = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _8e6a0fd6 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _44730d22 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _4868b244 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _3e3b505e = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _4a73f996 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _c14b9ff8 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _59989426 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _5705689e = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _10a97bea = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _6aa64681 = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _8f18a7fe = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _03881322 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _79710006 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _20a3f45e = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _482907a8 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _7469d7b0 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _6def922b = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _ceb3c89e = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _1e415b5a = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _86d4bb1c = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _9531aaa0 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _7ad2b33a = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _237187e8 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _51e7adec = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _49f3b7db = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _2854efe3 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _675fd4f9 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _73319b0a = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _66811353 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _9a1f097a = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _a462f2e8 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _4c8114f4 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _db75f60e = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _88145344 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _3a1bae73 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _2be52edb = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _b6cbba6e = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _04e9c6f5 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _57f12b00 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _4a27e31a = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _5a71a522 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _16e49a20 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _5448c0d6 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _084fd564 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _2e32a0ca = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _53801496 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _a313e910 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _1d89a9b6 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _569f5a48,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _569f5a48,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _569f5a48,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _569f5a48,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _569f5a48,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _569f5a48,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _569f5a48,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _569f5a48,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _49bee534,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _9e37e1f0,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _44159672,
    name: "account"
  }, {
    path: "/cart",
    component: _569f5a48,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _2f20d248,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _3beca707,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _78dd10f8,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _458b670f,
    name: "designs"
  }, {
    path: "/facets",
    component: _66c90a18,
    name: "facets"
  }, {
    path: "/favorites",
    component: _50cf74b1,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _18099fba,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _8e6a0fd6,
    name: "health"
  }, {
    path: "/not-found",
    component: _44730d22,
    name: "not-found"
  }, {
    path: "/proof",
    component: _4868b244,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _3e3b505e,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _4a73f996,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _c14b9ff8,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _59989426,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _5705689e,
    name: "signin"
  }, {
    path: "/signout",
    component: _10a97bea,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _6aa64681,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _8f18a7fe,
    name: "testy"
  }, {
    path: "/version",
    component: _03881322,
    name: "version"
  }, {
    path: "/version-app",
    component: _79710006,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _20a3f45e,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _482907a8,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _7469d7b0,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _6def922b,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _ceb3c89e,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _1e415b5a,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _49bee534,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _9e37e1f0,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _86d4bb1c,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _9531aaa0,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _7ad2b33a,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _237187e8,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _51e7adec,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _49f3b7db,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _2854efe3,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _675fd4f9,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _73319b0a,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _66811353,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _9a1f097a,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _a462f2e8,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _4c8114f4,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _db75f60e,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _88145344,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _3a1bae73,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _2be52edb,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _b6cbba6e,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _04e9c6f5,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _57f12b00,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _4a27e31a,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _5a71a522,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _16e49a20,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _5448c0d6,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _084fd564,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _2e32a0ca,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _53801496,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _a313e910,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _1d89a9b6,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
